class CouponLanguage {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.description = obj.description;
    this.language = obj.language;
  }
  toObject() {
    return {
      id :  this.id,
      name :  this.name,
      description :  this.description,
      language :  this.language,
    }
  }
}

var DataConverter = {
  toFirestore: function(cupones) {
    return {
      name: cupones.name,
      description: cupones.description,
      language: cupones.language,
    }
  },
  fromFirestore: function(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new CouponLanguage(data)
  }
}
export {
  CouponLanguage,
  DataConverter
}