<template>
  <div>
    <portal to="title-page">
      <h1 v-if="cupon">{{cupon.name}} <small>({{cupon.code}})</small></h1>
    </portal>
    <div>
      <v-card class="mx-auto elevation-1">
        <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiPencil}}
        </v-icon>
        <span class="title font-weight-light">Editar cupon</span>
        <v-btn small @click="$router.push({path:`/backend/admin/cupones/${$route.params.id}/images`})" color="success" class="ml-auto"><v-icon>{{mdiImageMultiple}}</v-icon> Imagenes</v-btn>
        <v-btn small @click.stop="dialogAddLang.show = true" color="primary" class="ml-2"><v-icon>{{mdiPlus}}</v-icon> lenguaje</v-btn>
        <v-btn small class="ml-2" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="validForm" lazy-validation v-if="cupon && cuponLanguage">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Tipo"
                  :prepend-icon="mdiText"
                  color="primary"
                  v-model="cupon.type"
                  required
                  disabled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-if="cupon.type == 'tour'"
                  label="Tour"
                  :prepend-icon="mdiPackageVariantClosed"
                  color="primary"
                  v-model="cupon.tour"
                  disabled
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-if="cupon.national == 'si'"
                  label="Nacional"
                  :prepend-icon="mdiPackageVariantClosed"
                  color="primary"
                  v-model="cupon.national"
                  required
                  disabled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" v-if="showDates">
                <v-menu
                  class="model"
                  v-model="menupicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      readonly
                      label="Periodo de fechas"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.rangeDates]"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" @change="updateDateRangeText" range no-title></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Codigo del cupon"
                  :prepend-icon="mdiTicket"
                  color="primary"
                  v-model="cupon.code"
                  required
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" v-if="cupon.national == 'no'">
                <v-text-field

                  label="Descuento Locales"
                  :prepend-icon="mdiPercent"
                  type="number"
                  color="primary"
                  v-model="cupon.localPercent"
                  required
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Descuento Nacionales"
                  :prepend-icon="mdiPercent"
                  type="number"
                  color="primary"
                  v-model="cupon.nationalPercent"
                  required
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" v-if="cupon.national == 'no'">
                <v-text-field

                  label="Descuento Extranjeros"
                  :prepend-icon="mdiPercent"
                  type="number"
                  color="primary"
                  v-model="cupon.foreignPercent"
                  required
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="py-0">
                <v-select
                  v-if="registerLanguages != null"
                  v-model="language"
                  :items="registerLanguages"
                  item-text="language"
                  return-object
                  :prepend-icon="mdiFlag"
                  :rules="[v => !!v || 'El idioma es requerido']"
                  label="Seleccione el idioma"
                  required
                  @change="changeLang"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Nombre"
                  :prepend-icon="mdiText"
                  color="primary"
                  v-model="cuponLanguage.name"
                  required
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p>Descripción</p>
                <vue-editor v-if="cuponLanguage"
                  color="primary"
                  :editor-toolbar="customToolbar"
                  v-model="cuponLanguage.description"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  dense
                ></vue-editor>
              </v-col>
              <v-col md="12" >
                <v-btn
                  :disabled="disableBtnAdd"
                  color="primary"
                  class="mr-4"
                  @click="validateForm()"
                >
                  <v-icon>{{mdiContentSave}}</v-icon> Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogAddLang.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Seleccione</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormLang"
            lazy-validation
          >
            <v-select
              v-if="availableLanguages != null"
              v-model="newLang"
              :items="availableLanguages"
              item-text="name"
              return-object
              :prepend-icon="mdiFlag"
              :rules="[v => !!v || 'El idioma es requerido']"
              label="Seleccione el idioma"
              required
            ></v-select>
            <p>Una vez seleccionado el lenguaje haga click en agregar y cuando se cierre esta ventana edite los datos.</p>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="dialogAddLang.show = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!validFormLang"
            @click="addLang"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mdiClose, mdiFlag, mdiText, mdiTicketPercentOutline, mdiPercent, mdiPencil, mdiPlus, mdiPackageVariantClosed, mdiArrowLeft, mdiContentSave, mdiImageMultiple } from "@mdi/js";
  import {DataConverter} from "@/FirestoreDataConverters/CuponLanguage.js";
  //import { VueEditor } from "vue2-editor";
  import Language from "@/models/Language.js";
  import Cupones from "@/models/Cupones.js";
  import firebase from 'firebase/app';
  import 'firebase/firestore';
  export default {
    name: "CuponesEdit",
    //components: { VueEditor },
    data() {
      return {
        //////////////////////////////////////////////
        mdiClose: mdiClose,
        mdiFlag: mdiFlag,
        mdiText: mdiText,
        mdiTicket: mdiTicketPercentOutline,
        mdiPlus: mdiPlus,
        mdiPencil: mdiPencil,
        mdiPercent: mdiPercent,
        mdiPackageVariantClosed: mdiPackageVariantClosed,
        mdiArrowLeft: mdiArrowLeft,
        mdiContentSave: mdiContentSave,
        mdiImageMultiple:mdiImageMultiple,
        //////////////////////////////////////////////
        CuponesModel: new Cupones(),
        languageModel: new Language(),
        //////////////////////////////////////////////
        unsubscribeLangs: null,
        registerLanguages: null,
        availableLanguages: null,
        systemLanguages: null,
        menupicker: false,
        language: null,
        cupon: null,
        newLang: null,
        cuponLanguage: null,
        selectedLanguageId:'es',
        dateRangeText:'',
        dates:['', ''],
        rules: {
          required: (v) => (v && v.length > 0) || "Campo requerido",
          min: (v) => v.length >= 8 || "Min 8 caracteres",
          rangeDates: (v) => {
            let dates = v.split(" ~ ");
            if (dates.length == 1) {
              return "Ingrese la fecha de fin";
            }
            let start = this.$moment(dates[0]);
            let end = this.$moment(dates[1]);
            if (end <= start) {
              return "La fecha de fin debe ser mayor a la fecha de inicio";
            }
            return true;
          },
        },
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
        validForm: true,
        validFormInit: true,
        snackbar: {
          show: false,
          color: "",
          message: "",
        },
        validFormLang:true,
        dialogAddLang:{
          show:false
        }
      };
    },
    methods: {
      validateForm() {
        this.validFormInit = false;
        this.cuponLanguage.description = this.cuponLanguage.description == null ? '' : this.cuponLanguage.description;
        if (this.$refs.form.validate()) {
          let loading = this.$loading.show();
          //this.CuponesModel.findByCode(this.cupon.code.toUpperCase()).get().then(snap => {
          let cupon
          if (this.cupon.type == 'tour') {
            cupon = this.CuponesModel.findByCodeAndTourId(this.cupon.code.toUpperCase(), this.cupon.idTour).get()
          } else {
            cupon = this.CuponesModel.findByCodeAndType(this.cupon.code.toUpperCase(), 'extra').get()
          }
          cupon.then(snap => {
            let cupon = !snap.empty ? snap.docs[0] : null;
            //1 validar que el codigo del cupon no exista
            console.log('cupon', cupon);
            console.log('cupon.id', cupon.id);
            console.log('this.cupon.id', this.cupon.id);
            if (cupon == null || (cupon && cupon.id == this.cupon.id)) {// si no existe o es el mismo
              if (this.cupon.national == 'si') {
                let data = {
                  code:this.cupon.code.toUpperCase(),
                  nationalPercent:this.cupon.nationalPercent
                };
                if (this.cuponLanguage.id == 'es') {
                  data.name = this.cuponLanguage.name;
                }
                console.log('data', data);
                this.ref.update(data).then(() => {
                  this.ref.collection('cuponesLanguages').doc(this.cuponLanguage.id).update(this.cuponLanguage.toObject()).then(() => {
                    loading.hide()
                    this.showSnackbarSuccess('El cupon se actualizo correctamente')
                  })
                })
                .catch(error => {
                  console.log(error);
                  this.showSnackbarError('Error al actualizar el cupon');
                })
              } else {
                let startDateMoment = this.$moment(this.dates[0]);
                let startDate = firebase.firestore.Timestamp.fromDate(startDateMoment.toDate());
                let endDateMoment = this.$moment(this.dates[1]);
                let endDate = firebase.firestore.Timestamp.fromDate(endDateMoment.toDate());
                let firstValidation = this.CuponesModel.searchCuponesAfterEndDate(this.cupon.idTour, startDate);
                firstValidation.get().then(qSnap => {
                  let traslaped = false;
                  let docStartDateMoment;
                  let docEndDateMoment;
                  let docCupon;
                  qSnap.forEach(doc => {
                    docCupon = doc.data();
                    docCupon.moment = this.$moment;
                    docStartDateMoment = this.$moment(docCupon.dateStart.toDate())
                    docEndDateMoment = this.$moment(docCupon.dateEnd.toDate())
                    let startTraslaped = startDateMoment >= docStartDateMoment && startDateMoment <= docEndDateMoment;
                    let endTraslaped = endDateMoment >= docStartDateMoment && endDateMoment <= docEndDateMoment;
                    let startTraslapedNR = docStartDateMoment >= startDateMoment && docStartDateMoment <= endDateMoment;
                    let endTraslapedNR = docEndDateMoment >= startDateMoment && docEndDateMoment <= endDateMoment;
                    if ((startTraslaped || endTraslaped || startTraslapedNR || endTraslapedNR) && docCupon.id != this.cupon.id) {
                      traslaped = docCupon;
                      return;
                    }
                  })
                  if (traslaped) {
                    this.showSnackbarError(`Existe una cupon del ${traslaped.formatStartDate()} al ${traslaped.formatEndDate()} | codigo: ${traslaped.code} | Tour: ${traslaped.tour}`);
                    loading.hide();
                  } else {
                    let data = {
                      code:this.cupon.code.toUpperCase(),
                      localPercent:this.cupon.localPercent ? this.cupon.localPercent : 0,
                      nationalPercent:this.cupon.nationalPercent ? this.cupon.nationalPercent : 0,
                      foreignPercent:this.cupon.foreignPercent ? this.cupon.foreignPercent : 0
                    };
                    if (this.cuponLanguage.id == 'es') {
                      data.name = this.cuponLanguage.name;
                    }
                    data.dateStart  = startDate;
                    data.dateEnd  = endDate;
                    console.log('data', data);
                    this.ref.update(data).then(()=>{
                      this.ref.collection('cuponesLanguages').doc(this.cuponLanguage.id).update(this.cuponLanguage.toObject()).then(() => {
                        loading.hide()
                        this.showSnackbarSuccess('El cupon se actualizo correctamente')
                      })
                    })
                    .catch((error) => {
                      loading.hide()
                      console.log('error', error);
                      this.showSnackbarError(`Error al actualizar el cupon, intente de nuevo o contacte al administrador`);
                    });
                  }
                })
              }
            } else {
              loading.hide()
              this.showSnackbarError(`El codigo ${this.cupon.code} ya esta registrado, intente con otro`);
            }
          })
          //2 validar que si el cupon no es nacional las fechas no se traslapen para el tour especifico 
        }
        /*let data = {
          type: this.cupon.type,
          name: this.cupon.name,
          code: this.cupon.code,
          percent: this.cupon.percent,
          dateStart: startFormatDate,
          dateEnd: endFormatDate,
          //status: this.cuponData.status,
        };
        if (this.language.id == 'es') {
          data.name = cuponLanguage.name
        }*/
      },
      // setDateRangeText() {
      //   this.dates[0] = this.$moment(this.rate.startDate.toDate()).format('YYYY-MM-DD');
      //   this.dates[1] = this.$moment(this.rate.endDate.toDate()).format('YYYY-MM-DD');
      //   this.dateRangeText = this.dates.join(' ~ ');
      // },
      updateDateRangeText() {
        this.dateRangeText = this.dates.join(' ~ ');
      },
      getLangs() {
        this.unsubscribeLangs = this.ref.collection('cuponesLanguages').withConverter(DataConverter).onSnapshot(docs => {
          this.registerLanguages = []
          var index, i = 0;
          docs.forEach(doc => {
            this.registerLanguages.push(doc.data());
            if (doc.data().id == this.selectedLanguageId) {
              index = i;
            }
            i++;
          })
          this.language = this.registerLanguages[index];
          this.cuponLanguage = this.registerLanguages[index];
          this.setAvailableLanguages();
        })
      },
      changeLang() {
        this.selectedLanguageId = this.language.id;
        this.cuponLanguage = this.language;
      },
      setAvailableLanguages() {
        this.availableLanguages = [];
        if (this.systemLanguages !== null) {
          if (this.registerLanguages !== null) {
            for(var i in this.systemLanguages) {
              let langId = this.systemLanguages[i].id;
              let exist = this.registerLanguages.find(item => item.id == langId);
              if (!exist) {
                this.availableLanguages.push(this.systemLanguages[i]);
              }
            }
          } else {
            this.availableLanguages = this.systemLanguages;
          }
        }
      },
      addLang() {
        if (this.$refs.formlang.validate()) {
          let loading = this.$loading.show();
          if (this.unsubscribeLangs) {
            this.unsubscribeLangs();
          }
          this.ref.collection('cuponesLanguages').doc(this.newLang.id).set({
            name:'',
            description:'',
            language:this.newLang.name,
          }).then(() => {
            this.getLangs();
            this.selectedLanguageId = this.newLang.id;
            loading.hide();
            this.showSnackbarSuccess('El lenguaje se agrego correctamente, ingrese la informacion correspendiente');
            this.dialogAddLang.show = false;
          });
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      setDateRangeText() {
        if (this.showDates) {
          this.dates[0] = this.$moment(this.cupon.dateStart.toDate()).format('YYYY-MM-DD');
          this.dates[1] = this.$moment(this.cupon.dateEnd.toDate()).format('YYYY-MM-DD');
          this.dateRangeText = this.dates.join(' ~ ');
        }
      },
    },
    mounted() {
      this.languageModel.getAll().onSnapshot(snap => {
        this.systemLanguages = [];
        snap.forEach( doc => {
          this.systemLanguages.push(doc.data());
        })
        this.setAvailableLanguages();
      })
      this.CuponesModel.findByCupon(this.$route.params.id).onSnapshot(doc => {
        this.cupon = doc.data();
        this.ref = doc.ref;
        if (this.unsubscribeLangs) {
          this.unsubscribeLangs();
        }
        this.getLangs();
        this.setDateRangeText();
      })
    },
    computed: {
      /*dateRangeText() {
        let datesStr = this.dates.join(" ~ ");
        if (datesStr == " ~ ") {
          return "";
        }
        return datesStr;
      },*/
      showDates() {
        return (this.cupon.type == 'tour' || this.cupon.type == 'extra') && !(this.cupon.national == 'si')
      },
      disableBtnAdd : function() {
        if (this.validFormInit) {
          return false;
        }
        if (!this.cuponLanguage.description) {
          return true;
        }
        return false;
      }
    },
    created() {
      this.$store.commit("SET_LAYOUT", "admin");
    },
  };
</script>